@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

@keyframes resizeAndShowText {
    0% {
        font-size: 0;
        opacity: 0;
    }

    50% {
        font-size: 0.9rem;
        opacity: 0;
    }

    100% {
        font-size: 0.9rem;
        opacity: 1;
    }
}

@keyframes resizeAndShowButton {
    0% {
        opacity: 0;
        font-size: 0;
        padding: 0;
        margin: 0;
    }

    50% {
        font-size: 0.65rem;
        padding: 0.7rem 2rem;
        margin-top: 1rem;
        opacity: 0;
    }

    100% {
        font-size: 0.65rem;
        padding: 0.7rem 2rem;
        margin-top: 1rem;
        opacity: 1;
    }
}

.pillarsFeature__display {
    position: relative;
}

.pillarsFeature__wrap {
    position: relative;
    overflow: hidden;

    @include breakpoint(lg) {
        .wrap {
            position: relative;
            overflow: visible;

            &:before {
                content: "";
                height: 135vh;
                width: 2px;
                background: #fff;
                position: absolute;
                left: 36%;
                top: 0;
                bottom: 0;
                z-index: 5;
                transform: translateY(-45vh);
            }
        }
    }
}

.pillarsFeature {
    height: 100vh;
    background: #000;
    color: #fff;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .magnolia-editmode & {
        height: auto;
        width: 100%;
        position: relative;
    }
}

.pillarsFeature__container {
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    z-index: 2;
    width: 100%;

    .magnolia-editmode & {
        top: auto;
        transform: none;
        position: relative;
    }
}

.pillarsFeature__contentContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include breakpoint(lg) {
        flex-direction: row;
    }
}

.pillarsFeature__titleContainer {
    font-weight: 200;
    width: 100%;
    font-size: 1.2rem;
    margin-bottom: 4rem;
    flex: auto;
    text-align: left;

    @include breakpoint(lg) {
        width: calc(100% / 3);
        flex: 1 1 33.3333%;
        text-align: right;
        margin-bottom: 0;
        font-size: 1.4rem;
    }
}

.pillarsFeature__titleContainerCenter {
    display: none;

    @include breakpoint(lg) {
        display: block;

        div:last-child {
            padding-bottom: 0.5rem;
        }
    }
}

.pillarsFeature__titleContainerCenter--mobile {
    text-align: center;
    display: block;
    border-bottom: 5px solid #1f5fa8;
    padding-bottom: 0.5rem;
    max-width: 400px;
    margin: 0 auto;

    @include breakpoint(lg) {
        display: none;
    }
}

@include forColors() using ($color, $value) {
    .colorTheme--#{$color} {
        .pillarsFeature__titleContainerCenter--mobile {
            border-bottom: 5px solid $value;
        }
    }
}

.pillarsFeature__itemsContainer {
    padding-left: calc(1 / 12 * 100%);
    width: 100%;
    flex: auto;
    border-left: 1.5px solid rgba(#ffffff, 0.75);

    @include breakpoint(lg) {
        margin: 0 0 auto 2rem;
        width: calc(100% * 2 / 3);
        flex: 1 1 66.6667%;
        padding-left: 3%;
        padding-right: 3%;
        border-left: none;
    }
}

.pillarsFeatureItem__title {
    transition: all 0.3s ease-in-out;
    margin: 0;
    line-height: 1;
    font-size: 1rem;
    text-transform: uppercase;

    @include breakpoint(lg) {
        font-size: 1.25rem;
        line-height: auto;
    }

    a {
        color: #fff;
        text-decoration: none;
    }
}

.pillarsFeatureItem__text {
    font-size: 0;
    opacity: 0;

    // shorter height screens ie -- iphone 5/SE
    @media screen and (device-aspect-ratio: 40/71) {
        display: none;
    }
}

.pillarsFeatureItem__link {
    text-transform: uppercase;
    background-color: #37b4e4;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    opacity: 0;
    font-size: 0;
    padding: 0;
    margin: 0;
    transition: background 0.2s ease-in-out;
    min-width: auto;

    &:hover,
    &:focus,
    &:active {
        background-color: #1f5fa8;
    }
}

.pillarsFeatureItem {
    margin: 0;
    max-width: 100%;
    transition: margin 0.3s ease-in-out;

    &:not(:first-child) {
        margin-top: 1rem;
    }

    @include breakpoint(lg) {
        max-width: 50%;
    }

    &.js-active,
    .magnolia-editmode & {
        margin-bottom: 1rem;
        max-width: none;

        @include breakpoint(lg) {
            margin-bottom: 4rem;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .pillarsFeatureItem__title {
            font-size: 1.5rem;

            @include breakpoint(lg) {
                font-size: 2rem;
            }
        }

        .pillarsFeatureItem__text {
            font-size: 1rem;
            opacity: 1;
            margin-top: 1rem;
            animation: resizeAndShowText 0.4s ease-in-out both;
        }

        .pillarsFeatureItem__link {
            font-size: 0.75rem;
            padding: 1rem 4rem;
            margin-top: 1rem;
            opacity: 1;
            animation: resizeAndShowButton 0.4s ease-in-out both;
        }
    }

    .button.pillarsFeatureItem__link {
        display: none;
    }
}

.pillarsFeatureItem__backgroundContainer {
    height: 100vh;
    width: 100vw;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    .pillarsFeature--isInFocus & {
        position: fixed;
    }

    &.js-active {
        opacity: 1;
    }

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(1, 27, 45, 0) 0%, rgba(1, 27, 45, 0.65) 48%, rgba(1, 27, 45, 0.32) 81%, rgba(1, 27, 45, 0) 100%);
    }
}

.pillarsFeatureItem__background {
    width: 125vw;
    height: 125vh;
    left: -12.5vw;
    top: -12.5vh;
    position: absolute;
    background-position: 50% top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    transform: scale(1);
    transition: transform 0.5s ease-in-out;

    .js-active & {
        transform: scale(0.9);
    }
}

.pillarsFeatureItem__spacerShelf {
    display: flex;
    flex-direction: column;
}

.pillarsFeatureItem__spacer {
    position: relative;
    height: 45vh;
    width: 10vw;

    @include breakpoint(lg) {
        height: 25vh;
    }
}

.pillarsFeatureItem__spacerFirst,
.pillarsFeatureItem__spacerLast {
    flex-grow: 4;
    height: 50vh;
}

.pillarsFeature--isInFocus {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    .pillarsFeature__container {
        position: fixed;
    }
}

.pillarsFeature--isAtBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
}
