@use "../variables" as *;
body{margin:0;font-family: 'Roboto', sans-serif;min-width: 360px;}
*{box-sizing: border-box;}
	
	

	.home_overview{
		display:flex;
		justify-content:center;
		color: white;
		padding: 0 0 4em 0;
	}

	.home_overview:first-of-type {
		padding-top: 4em;
		padding-bottom: 1em;
	}

	.home_overview__container{
		display:flex;
		width:100%;
		max-width: 1172px;
		padding:0 8px;
		flex-flow: column;
	}
	.home_overview__container .home_overview__container__row{
		display: flex;
		width: 100%;
		flex-flow: row;
	}
	.home_overview__container__row__col{
		flex:50%;
		padding:24px 16px;
		margin: 0 8px;
		background-position: top right;
		background-repeat: no-repeat;
		background-size: cover;
		color: #fff;
	}

	.home_overview__container__row__col.fullbleed {
		padding: 0;
	}

	.home_overview__container__row__col.sixty {
		flex: 60%;
	}

	.home_overview__container__row__col.fourty {
		flex: 40%;
	}

	.home_overview__container__row__col__overlay{
		display:flex;
		flex-direction: column;
		width:85%;
		height: 100%;
		padding: 16px 32px 16px 16px;
	}

	.home_overview__container__row__col.fullbleed .home_overview__container__row__col__overlay {
		width: 100%;
		padding-bottom: 16px;
	}

	.color-dark-green{
		background: $color-jabil-slider-dark-green;
	}
	
	.color-dark-blue{
		background: $color-jabil-slider-dark-blue;
	}

	.home_overview__container__row__col.fullbleed .color-dark-green {
		background: rgb(0,138,94);
        background: linear-gradient(90deg, rgba(0,138,94,1) 0%, rgba(0,138,94,0.15) 100%);
	}

	.home_overview__container__row__col.fullbleed .color-dark-blue {
		background-color: #002b49;
	}

	.home_overview__container__row__col .category{font-size: 14px;font-weight: 300;padding:0;margin:0;}
	.home_overview__container__row__col .title{font-size: 22px; padding:16px 0;margin:0; font-weight: 600;}
	.home_overview__container__row__col .content {font-size:16px;font-weight: 300;padding:0;margin:0;}
	.home_overview__container__row__col .link{padding-top: 24px;}
	.home_overview__container__row__col .link a{color:white; text-decoration:underline;}



	.body_ribbon{
		display:flex;
		flex: 1;
		padding: 48px 0;
		justify-content: center;
	}
	.body_ribbon h4{
		max-width:600px;
		text-align: center;
		font-size: 24px;
		font-weight: 300;
		padding: 0;
		margin: 0;
	}
	.home_pillars{
		display: flex;
  		flex-wrap: wrap;
		position: relative;
	}
	.home_pillars_pillar{
		flex: 1;
		height: 200px;
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
	}
	.home_pillars_pillar_overlay{
		display:flex;
		flex-direction: column;
		background: rgba(0,43,73,.60);
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		transition: all 0.2s ease-in;
	}
	.home_pillars_pillar_overlay h3{
		width: 100%;
		padding: 16px 0;
		margin: 0;
		color:white;
		font-weight: 500;
		font-size: 21px;
		text-transform: uppercase;
		text-align: center;
		transition: all ease-in-out .25s;
	}
	.home_pillars:hover h3{
		opacity:.2;
		transform: translateY(-50%);
	}

	.home_pillars_pillar_overlay:hover {
		background: rgba(21,190,240,.75);
	}

	.home_pillars_pillar_overlay:hover > h3{
		opacity:1;
	}

	.home_pillars_content{
		width:100%;
		padding:16px;
		background: rgba(0,43,73,.9);
		opacity: 0;
		transition: all ease-in-out .35s;
		pointer-events: none;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		transform: translateY(-5px) scale(1.03);
		filter: blur(6px);
	}
	.home_pillars_content p{
		width:85%;
		max-width: 1200px;
		margin:0 auto;
		text-align: center;
		color:white;
		font-size: 18px;
	}

	.home_pillars_pillar:hover .home_pillars_content {
		opacity: 1;
		transform: translateY(0) scale(1);
		filter: blur(0);
	}

@media only screen and (max-width: 1280px){
	.home_pillars:hover h3{
		opacity:.2;
		transform: translateY(-65%);
	}

	.home_pillars_pillar_overlay:hover > h3{
		opacity:1;
	}

	.home_pillars_content p{
		max-width: 900px;
	}

}



@media only screen and (max-width: 900px){
	.home_overview__container .home_overview__container__row{
		flex-direction:column;
	}
	.home_overview__container .home_overview__container__row .home_overview__container__row__col{
		background-size: cover;
	}
	.home_overview__container .home_overview__container__row .home_overview__container__row__col:first-child{
		margin-bottom: 16px;
	}
	.body_ribbon{
		padding:32px 16px;
	}
	.body_ribbon h4{
		font-size: 18px;
	}
	.home_pillars_pillar{
		flex: 33.33333333%;
	}
	.home_pillars_content {
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0,43,73,1);
	}

	.home_pillars_pillar:nth-of-type(4) .home_pillars_content, .home_pillars_pillar:nth-of-type(5) .home_pillars_content, .home_pillars_pillar:nth-of-type(6) .home_pillars_content {
		top: 0;
		bottom: auto;
	}

	.home_pillars:hover h3{
		opacity: .1;
		transform: translateY(0);
	}

	.home_pillars_pillar_overlay:hover > h3{
		opacity:1;
	}

}





@media only screen and (max-width: 500px){
	.home_pillars_pillar{
		flex: 50%;
	}
	.home_pillars_pillar_overlay h3{
		font-size: 18px;
	}

	.home_pillars_content p{
		font-size: 13px;
	}

	.home_pillars_pillar:nth-of-type(1) .home_pillars_content, .home_pillars_pillar:nth-of-type(2) .home_pillars_content, .home_pillars_pillar:nth-of-type(5) .home_pillars_content, .home_pillars_pillar:nth-of-type(6) .home_pillars_content {
		top: 200px;
		bottom: auto;
	}

	.home_pillars_pillar:nth-of-type(3) .home_pillars_content, .home_pillars_pillar:nth-of-type(4) .home_pillars_content {
		top: 400px;
		bottom: auto;
	}


}



















.home_overview__container .home_overview__container__row .home_overview__container__row__slider{
    flex:50%;
    margin: 0 8px;
    background: #d8dada;
    position: relative;
    overflow: hidden;
}

.home_overview__container__row__slider__inner{
    display: flex;
    width: 100%;
    transition: all .3s;
}

.home_overview__container__row__slider__inner__slide{
    padding:24px 16px;
    background-position: top right;
    background-repeat: no-repeat;
    background-size:cover;
	display: flex;
    flex:none;
    width: 100%;
}
.home_overview__container .home_overview__container__row .home_overview__container__row__slider .home_overview__container__row__slider__inner__slide__overlay{
    display:flex;
    flex-direction: column;
    width:85%;
    height: 100%;
    padding: 16px 32px 16px 16px;
}

.home_overview__container__row .category	{color:#fff; text-align: left; font-size: 14px;font-weight: 300;padding:0;margin:0;}
.home_overview__container__row .title	{color:#fff; text-align: left; font-size: 24px;font-weight: 600;padding:16px 0;margin:0;}
.home_overview__container__row .content	{color:#fff; text-align: left; font-size:16px;font-weight: 300;padding:0;margin:0;}
.home_overview__container__row .link	{padding-top: 24px;} 
.home_overview__container__row .link a	{color:#fff; text-align: left; transition: all ease-in-out .3s; opacity: 1;}
.home_overview__container__row .link a:hover	{opacity: .5;}

.home_overview__container__row__col.sixty .content, .home_overview__container__row__col .title {
	max-width: 70%;
}

.home_overview__container__row__slider .indicators {
display: flex;
position: absolute;
bottom: 12px;
right: 8px;
z-index: 1;
}

.home_overview__container__row__slider .indicators .indicator {
margin: 0px 5px;
width: 15px;
height: 15px;
background-color:rgba(255,255,255,.50);
border:2px solid white;
border-radius: 50%;
cursor: pointer;
transition: all .3s;
-webkit-box-shadow: 4px 4px 15px 4px rgba(0,0,0,0.25); 
box-shadow: 4px 4px 15px 4px rgba(0,0,0,0.25);
}

.home_overview__container__row__slider .indicators .indicator[active] {
opacity: 1;
border: 2px solid white;
background:#002B49;
}



@media only screen and (max-width: 850px){
.home_overview__container .home_overview__container__row .home_overview__container__row__slider:first-child{
    margin-bottom: 16px;
}
}

