@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.featureBand {
    background: $color-jabil-dark-blue2;
    padding-bottom: 2em;
    padding-top: 1.5em;

    @include breakpoint(lg) {
        padding-bottom: 1.8em;
        position: relative;
        z-index: 2;
    }
}

.featureBand__wrap {
    @include breakpoint(lg) {
        display: flex;
        flex-direction: row;
        padding-bottom: 0;
    }

    @media screen and (max-width: $width-tablet-max) {
        max-width: 500px;
    }
}

.featureBandItem {
    transition: all 0.3s ease-in;
    text-decoration: none;
    scroll-snap-align: start;
    position: relative;
    display: block;

    &:not(:first-child) {
        @media screen and (max-width: $width-tablet-max) {
            border-top: 3px solid $color-jabil-darker-blue;
            margin-top: 25px;
            padding-top: 25px;
        }
    }

    @include breakpoint(lg) {
        flex-direction: column;
        display: flex;
        align-items: center;
        width: 33.33%;
        scroll-snap-align: unset;
        min-width: auto;
    }
}

.featureBandItem__content {
    color: #fff;
    padding: 0 1rem;

    @include breakpoint(lg) {
        padding: 0;
        max-width: 80%;
    }
}

.featureBandItem__textWrap {
    display: block;

    @include breakpoint(lg) {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        justify-content: space-between;
        flex-grow: 1;
    }
}

.featureBandItem__title {
    font-size: 1rem;
    line-height: 1.2;
    text-shadow: 2px 2px 7px $color-black-transparent-50;
    margin-bottom: 12px;
}

.featureBandItem__desc {
    margin-top: 12px;
    font-size: 0.8rem;
}

.featureBandItem__mobileSeeMore {
    display: block;
    color: $color-jabil-light-blue;
    margin-top: 26px;
    font-size: 0.9rem;

    @include breakpoint(lg) {
        display: none;
    }
}

//Admin Styles
.featureBand--admin {
    .featureBand__wrap {
        width: 100%;
    }
}

.featureBandItem--admin {
    float: left;
    width: 33.33%;

    .featureBandItem {
        border-top: none;
        width: 100%;
    }
}
