@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

/* stylelint-disable */
.video__container {
    margin-top: 35px;
    margin-bottom: 35px;
    border: 4px solid rgba(234, 235, 236, .6);

    &:first-child {
        margin-top: 0;
    }
}

.video__wrapper {
    position: relative;
    height: 100%;
}

.video__videoCover {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &:after {
        content: "";
        background-color: $color-black-transparent-25;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        transition: 0.3s ease-in-out;
    }
}

.video {
    position: relative;
    height: 100%;

    &:hover {
        .video__videoCover {
            &:after {
                background: rgba(0, 0, 0, 0.4);
            }
        }
    }
}

.video--youtube {
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.videoCover__text {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    z-index: 3;

    &:before {
        content: "";
        background: url(../../src/img/icons/play-circle-white.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        height: 38px;
        width: 38px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        @include breakpoint(md) {
            height: 75px;
            width: 75px;
        }

        @include breakpoint(lg) {
            height: 75px;
            width: 75px;
        }
    }

    &.video--noPlayIcon {
        &:before {
            content: none;
        }
    }
}

.video__title,
.video__subtitle {
    color: $color-white;
    line-height: 1.2;
    margin-bottom: 0;
    text-align: center;
    white-space: normal;
}

.video__title {
    font-size: 0.8rem;
    font-weight: normal;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    padding: 0 10px;

    @include breakpoint(md) {
        font-size: 1.2rem;
    }

    @include breakpoint(lg) {
        font-size: 1.1rem;
    }
}

.video__title:after {
    content: " ";
    display: block;
    background-color: $color-jabil-light-blue;
    border: none;
    height: 3px;
    margin: 4px auto 4px auto;
    width: 40%;
    max-width: 250px;

    @media screen and (min-width: $width-tablet-min) {
        margin: 7px auto 10px auto;
    }

    @include breakpoint(md) {
        margin: 7px auto 15px auto;
    }

    @include breakpoint(lg) {
        margin: 7px auto;
    }
}

.video__subtitle {
    font-size: 0.9rem;
    font-weight: 300;
    margin-bottom: 20px;
    position: relative;
    height: 100%;
    z-index: 1;
    padding: 6px 10px;
}

.video__embed {
    width: 100%;
    margin: auto;
    display: block;
}

@include forColors(
        $optOut: (
            "orange"
        )
    )
    using ($color, $value) {
    .colorTheme--#{$color} {
        .video__title:after {
            background-color: $value;
        }

        .video__subtitle {
            background-color: $value;
        }
    }
}

// Healthcare wants most of the video blue but this thing orange
.colorTheme--orange {
    .video__title:after {
        background-color: map-get($colorPops, "orange");
    }
}

.page__sidebar {
    @include breakpoint(md) {
        .video__title {
            font-size: 0.8rem;

            @include breakpoint(md) {
                font-size: 1.1rem;
            }

            @include breakpoint(md-lg) {
                font-size: 1.2rem;
            }

            @include breakpoint(lg) {
                font-size: 1.1rem;
            }
        }

        .video__title:after {
            height: 3px;
            margin: 8px auto;
            max-width: 200px;
        }

        .video__subtitle {
            font-size: 0.75rem;
            margin-bottom: 0;
        }
    }
}

.popover__overlay {
    background: rgba(0, 0, 0, 0.58) linear-gradient(90deg, rgba(7, 35, 62, 0.9) 0%, rgba(0, 48, 85, 0.9) 100%) repeat scroll 0% 0% !important;
}

.videoCover__img {
    cursor: pointer;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    overflow: hidden;
}

#vidyard-overlay-wrapper .vidyard-close-container {
    top: 2.5%;
    right: 4%;
}

#vidyard-popbox-constraint.landscape {
    max-width: unset;
}

//video text styles on columns
.columns--2-up {
    .columns__column {
        .videoCover__text {
            &:before {
                height: 52px;
                width: 52px;

                @media screen and (max-width: $width-large-tablet-min) {
                    width: 45px;
                }
            }

            .video__title {
                @media screen and (max-width: $width-large-tablet-min) {
                    font-size: 1em;
                }

                @media screen and (max-width: $width-medium-tablet-min) {
                    font-size: 0.9em;
                }
            }

            .video__title:after {
                @media screen and (max-width: $width-medium-tablet-min) {
                    margin: 2px auto;
                }
            }
        }
    }
}

.columns--3-up {
    .columns__column {
        .videoCover__text {
            padding: 5px;

            @include breakpoint(lg) {
                padding: 10px;
            }

            &:before {
                height: 52px;
                width: 45px;

                @include breakpoint(lg) {
                    width: 50px;
                }
            }

            .video__title {
                font-size: 0.9em;

                @include breakpoint(md) {
                    font-size: 1.2rem;
                }

                @media screen and (max-width: 900px) and (min-width: 768px) {
                    font-size: 0.7em;
                }

                @include breakpoint(md-lg) {
                    font-size: 0.8rem;
                }

                @include breakpoint(lg) {
                    font-size: 1em;
                }
            }

            .video__title:after {
                margin: 4px auto;

                @include breakpoint(md) {
                    margin: 7px auto 15px;
                }

                @media screen and (max-width: 900px) and (min-width: 768px) {
                    margin: 5px auto;
                }

                @include breakpoint(md-lg) {
                    margin: 7px auto 2px;
                }

                @include breakpoint(lg) {
                    margin: 7px auto;
                }
            }
        }
    }
}

#vidyard-popbox {
    @media screen and (max-width: $width-standard-screen-max) {
        width: 100%;
    }
}
