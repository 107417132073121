@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.homepageHero {
    height: 82vh;
    overflow: hidden;
    position: relative;

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: auto;
        z-index: 1;
        width: 100%;
        height: 25vh;
        top: 50%;
        background: linear-gradient(180deg, rgba(21, 190, 240, 0.95) 0%, rgba(21, 190, 240, 0.8) 40%, rgba(21, 190, 240, 0.3473098712141106) 80%, rgba(21, 190, 240, 0) 100%);

        @include breakpoint(md) {
            height: 100%;
            width: 50%;
            background: $color-jabil-light-blue;
            background: linear-gradient(90deg, rgba(21, 190, 240, 0.95) 0%, rgba(21, 190, 240, 0.8) 40%, rgba(21, 190, 240, 0.3473098712141106) 80%, rgba(21, 190, 240, 0) 100%);
            top: 0;
        }
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: auto;
        background: $color-jabil-darker-blue;
        z-index: 2;
        width: 100vw;
        height: 46vh;

        @include breakpoint(md) {
            height: 100%;
            width: 50%;
        }
    }

    .homepageHero__video {
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        transform: translateX(-50%) translateY(-50%);

        .video__container {
            height: 100vh !important;

            .video {
                position: absolute;
                display: block;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;

                @media screen and (max-width: 1366px) {
                    width: 112%;

                    @media screen and (min-height: 1000px) {
                        width: 140%;
                    }
                }

                @media screen and (max-width: 1024px) {
                    width: 140%;

                    @media screen and (min-height: 1340px) {
                        width: 248%;
                    }
                }

                @media screen and (max-width: 960px) {
                    width: 155%;

                    @media screen and (min-height: 1000px) {
                        width: 248%;
                    }

                    @media screen and (min-height: 1270px) {
                        width: 300%;
                    }
                }

                @media screen and (max-width: 600px) {
                    @media screen and (orientation: portrait) {
                        width: 330%;
                    }

                    @media screen and (min-height: 800px) {
                        width: 400%;
                    }
                }
            }

            .vidyard-player-container {
                top: -2%;

                @media screen and (max-height: 500px) {
                    top: -3%;
                }
            }
        }

        .videoCover__text {
            &::before {
                display: none;
            }
        }
    }

    video.homepageHero__video {
        @include breakpoint(sm) {
            height: 50vh;
            min-height: 50vh;
            width: auto;
            min-width: 242%;
            left: 0;
            transform: translateX(-75vw) translateY(0);
            background-size: auto 50vh;
        }

        @include breakpoint(lg) {
            left: 70%;
            top: 55%;
            height: 100vh;
            background-size: auto 100vh;
        }

        @include breakpoint(xl) {
            left: 73%;
        }
    }

    .lottiePlayer {
        width: 100%;
        height: 75vh;
        position: absolute;
        z-index: 5;
        top: 5vh;

        @include breakpoint(sm) {
            width: 150vw;
            left: 50%;
            transform: translateX(-50%);
            top: -15%;
        }

        @include breakpoint(lg) {
            top: 8vh;
        }
    }

    .homepageHero__searchContainer {
        margin-top: 0;
        opacity: 0;
        pointer-events: none;
        transition: all ease-in-out 0.3s;
        position: absolute;
        width: 96%;
        left: 2%;
        top: 0.3em;
        display: inline-block;

        &:before {
            background-image: url("../../src/img/icons/search-icon-blk.svg");
            width: 16px;
            height: 16px;
            content: "";
            position: absolute;
            left: 0.75rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
        }
    }

    .homepageHero__searchForm {
        position: absolute;
        top: 45px;
        background: $color-white;
        z-index: 7;
        color: $color-grey-3c;
        padding: 0.5em 1.6em;
        display: flex;
        max-width: 420px;
        width: 232px;
        border: 2px solid $color-white-transparent-50;
        border-top: none;
        box-shadow: 0px 0px 20px $color-black-transparent-30;
        border-radius: 0 0 1em 1em;
        transition: all ease-in-out 0.4s;
        left: 50%;
        transform: translateX(-402px);
        justify-content: center;

        .jabil-one-mx & {
            width: 260px;
        }

        h2 {
            font-size: 14px;
            line-height: 1em;
            display: flex;
            text-align: center;
            font-weight: 400;
            align-items: center;
            transition: all ease-in-out 0.2s;
            opacity: 1;

            img {
                height: 16px;
                margin-right: 10px;
            }
        }

        &:hover {
            width: 100%;
            max-width: 400px;
            padding: 0.5em 1em 0.75em 1em;

            .homepageHero__searchContainer {
                opacity: 1;
                transition-delay: 0.35s;
                pointer-events: auto;
            }

            h2 {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .homepageHero__search {
        height: 1.75em;
        border-radius: 1em;
        padding-left: 2.5em;
        font-size: 15px;
        position: relative;
        z-index: 6;
        margin-top: 0;
        border: none;
        width: 100%;
        background-repeat: no-repeat;
    }

    .homepageHero__wrap {
        display: none;

        @include breakpoint(lg) {
            display: block;
            max-width: 862px;
        }
    }

    .hasEnabledInvestorBar & {
        .homepageHero__searchForm {
            top: 89px;
        }

        .lottiePlayer {
            @include breakpoint(lg) {
                top: 9.5vh;
            }
        }
    }
}
