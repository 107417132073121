@use "../base";
@use "../variables" as *;
@use "../mixins/breakpoint" as *;

@use "../modules/homeHero";
@use "../modules/pillarsFeature";
@use "../modules/video";
@use "../modules/title";
@use "../modules/featureBandHome";
@use "../modules/sliderContainer";
@use "../modules/sliderFull";

.page--home {
    .video__container {
        height: 325px;
        overflow: hidden;
        margin-bottom: 0;
        margin-top: 0;

        @include breakpoint(sm) {
            height: 280px;
        }
    }

    .video {
        .video__videoCover:after {
            background-color: transparent;
        }

        .videoCover__text {
            padding-bottom: 5rem;
        }

        .video__title:after {
            display: none;
        }

        .video__embed {
            @media screen and (max-width: $width-small-tablet-max) {
                max-width: none;
                width: auto;
                height: 100%;
            }
        }

        &:hover {
            .video__videoCover:after {
                background: none;
            }
            .videoCover__text:before {
                opacity: 0.9;
            }
        }
    }

    .mgnlEditor.mgnlPlaceholder {
        position: static !important;
    }
}
