@use "../variables" as *;
.content {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-size: cover;
	// padding: 100px 0;
}
.fullwidth_slider {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.fullwidth_slider .slider-inner {
	display: flex;
	width: 100%;
	transition: all .3s;
}

.fullwidth_slider .slide {
	flex: none;
	width: 100%;
}

.fullwidth_slider .indicators {
	display: none; /*flex*/
	justify-content: center;
	position: absolute;
	bottom: 24px;
	z-index: 1;
	width: 100%;
}

.fullwidth_slider .indicators .indicator {
	margin: 0px 5px;
	width: 15px;
	height: 15px;
	background-color:rgba(255,255,255,.50);
	border:2px solid white;
	border-radius: 50%;
	cursor: pointer;
	transition: all .3s;
	-webkit-box-shadow: 4px 4px 15px 4px rgba(0,0,0,0.25);
	box-shadow: 4px 4px 15px 4px rgba(0,0,0,0.25);
}

.fullwidth_slider .indicators .indicator[active] {
	opacity: 1;
	border: 2px solid white;
	background:#002B49;
}

.fullwidth_slider .arrows {
	display: flex;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 72px;
}

.fullwidth_slider .arrows .arrow-prev,
.fullwidth_slider .arrows .arrow-next {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 45px;
	height: 72px;
	background: transparent;
	border-radius: 0;
	cursor: pointer;
	font-size: 102px;
	color: #fff;
	font-weight: 500;
	text-shadow: 1px 1px 8px rgba(0,0,0,.2);
	transform: scaleY(160%);
	transition: all ease-in-out .4s;
	opacity: 1;
}

.fullwidth_slider .arrows .arrow-prev {
	left: 5%;
}

.fullwidth_slider .arrows .arrow-next {
	right: 5%;
}


.fullwidth_slider .arrows .arrow-prev:hover {
	left: 4.7%;

}
.fullwidth_slider .arrows .arrow-next:hover {
	right: 4.7%;
}

.fullwidth_slider .slide .overlay{
	width: 75%;
	max-width: 1000px;
	padding: 66px 40px;
	color: #fff;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.fullwidth_slider .slide .overlay h3{
	font-size:28px;
	font-weight: 400;
	margin: 0 0;
	padding: 0;
	width: 100%;
}

.fullwidth_slider .slide .overlay h4{
	font-size:18px;
	font-weight: 300;
	margin: 0 0 50px 0;
	padding: 0;
	width: 100%;

}

.fullwidth_slider .slide .overlay p.title{
	font-size:19px;
	font-weight: 500;
	margin: 0 0 8px;
	padding: 0;
	color: #fff;

}

.fullwidth_slider .slide .overlay p{
	font-size:18px;
	font-weight: 300;
	margin: 0 0 16px;
	padding: 0;

}

.fullwidth_slider .slide .overlay a{
	display: inline-block;
	padding: 8px 16px;
	margin: 8px 0 0 0;
	text-align: center;
	border:1px solid white;
	color:inherit;
	border-radius: 4px;
	font-size: 16px;
	text-decoration:none;
}
.fullwidth_slider .slide .overlay a:hover{
	background: white;
	color:#022B49;
}

@media only screen and (max-width: 700px){

	.content {
		padding: 60px 0;
	}

	.fullwidth_slider .slide .overlay {
		width: 84%;
		padding: 20px;
	}

	.fullwidth_slider .slide .overlay h3 {
		font-size: 20px;
	}

	.fullwidth_slider .slide .overlay h4 {
		font-size: 16px;
		margin-bottom: 15px;
	}

	.fullwidth_slider .slide .overlay p {
		font-size: 13px;
	}

	.fullwidth_slider .arrows .arrow-next, .fullwidth_slider .arrows .arrow-prev {
		font-size: 50px;
		height: 50px;
		width: 25px;
	}

	.fullwidth_slider .arrows .arrow-next {
		right: .2%;
	}

	.fullwidth_slider .arrows .arrow-prev {
		left: .2%;
	}

}
